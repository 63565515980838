function register() {
    window.addEventListener('load', async () => {
        if ('serviceWorker' in navigator) {
            try {
                if (localStorage.getItem('pwaInstalled') !== '1') {
                    document.body.classList.add('pwa-installing');
                }
                await navigator.serviceWorker.register(`/sw.js`, { scope: '/' });
            } catch (err) {
                console.log('SW registration failed : ', err);
            }

            navigator.serviceWorker.addEventListener('message', (event) => {
                if (event.data && event.data.type === 'pwaInstalled') {
                    document.body.classList.remove('pwa-installing');
                    document.body.classList.add('pwa-installed');
                    localStorage.setItem('pwaInstalled', '1');
                    setTimeout(() => {
                        document.body.classList.remove('pwa-installed');
                    }, 10000);
                }
            });
        }
    });
}

async function unregister() {
    if ('serviceWorker' in navigator) {
        const registrations = await navigator.serviceWorker.getRegistrations();
        registrations.forEach((registration) => {
            registration.unregister();
        });
    }
}

const _module = { register, unregister };

export default _module;
