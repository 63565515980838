type TpsInCity = {
    name: string;
    disabled?: boolean;
    slug: string;
};

export type CitySlug =
    | 'reftinskiy'
    | 'novosibirsk'
    | 'kuybishev'
    | 'barnaul'
    | 'biysk'
    | 'belovo'
    | 'kemerovo'
    | 'novokuznetsk'
    | 'myski'
    | 'abakan'
    | 'kansk'
    | 'zelenogorsk'
    | 'krasnoyarsk'
    | 'minusinsk'
    | 'nazarovo'
    | 'luchegorsk';

type CityInfo = {
    name: string;
    slug: CitySlug;
    region: string;
    value: number;
    tps: TpsInCity[];
};

export const citiesData: CityInfo[] = [
    {
        name: 'Рефтинский',
        slug: 'reftinskiy',
        region: 'Свердловская область',
        value: 4630000,
        tps: [
            {
                name: 'Рефтинская ГРЭС',
                slug: 'reftinskaya-gres',
            },
        ],
    },
    {
        name: 'Новосибирск',
        slug: 'novosibirsk',
        region: 'Новосибирская область',
        value: 548000,
        tps: [
            {
                name: 'Новосибирская ТЭЦ-2',
                slug: 'novosibirskaya-tets-2',
            },
            {
                name: 'Новосибирская ТЭЦ-3',
                slug: 'novosibirskaya-tets-3',
            },
            {
                name: 'Новосибирская ТЭЦ-4',
                disabled: true,
                slug: 'novosibirskaya-tets-4',
            },
            {
                name: 'Новосибирская ТЭЦ-5',
                slug: 'novosibirskaya-tets-5',
            },
        ],
    },
    {
        name: 'Куйбышев',
        slug: 'kuybishev',
        region: 'Новосибирская область',
        value: 26000,
        tps: [
            {
                name: 'Барабинская ТЭЦ',
                disabled: true,
                slug: 'barabinskaya-tets',
            },
        ],
    },
    {
        name: 'Барнаул',
        slug: 'barnaul',
        region: 'Алтайский край',
        value: 223000,
        tps: [
            {
                name: 'Барнаульская ТЭЦ-2',
                slug: 'barnaulskaya-tets-2',
            },
            {
                name: 'Барнаульская ТЭЦ-3',
                slug: 'barnaulskaya-tets-3',
            },
        ],
    },
    {
        name: 'Бийск',
        slug: 'biysk',
        region: 'Алтайский край',
        value: 177000,
        tps: [
            {
                name: 'Бийская ТЭЦ',
                slug: 'biyskaya-tets',
            },
        ],
    },
    {
        name: 'Белово',
        slug: 'belovo',
        region: 'Кемеровская область',
        value: 379000,
        tps: [
            {
                name: 'Беловская ГРЭС',
                slug: 'belovskaya-gres',
            },
        ],
    },
    {
        name: 'Кемерово',
        slug: 'kemerovo',
        region: 'Кемеровская область',
        value: 384000,
        tps: [
            {
                name: 'Кемеровская ГРЭС',
                slug: 'kemerovskaya-gres',
            },
            {
                name: 'Кемеровская ТЭЦ',
                slug: 'kemerovskaya-tets',
            },
            {
                name: 'Ново-Кемеровская ТЭЦ',
                slug: 'novo-kemerovskaya-tets',
            },
        ],
    },
    {
        name: 'Новокузнецк',
        slug: 'novokuznetsk',
        region: 'Кемеровская область',
        value: 92000,
        tps: [
            {
                name: 'Кузнецкая ТЭЦ',
                disabled: true,
                slug: 'kuznetskaya-tets',
            },
        ],
    },
    {
        name: 'Мыски',
        slug: 'myski',
        region: 'Кемеровская область',
        value: 455000,
        tps: [
            {
                name: 'Томь-Усинская ГРЭС',
                slug: 'tom-usinskaya-gres',
            },
        ],
    },
    {
        name: 'Абакан',
        slug: 'abakan',
        region: 'Республика Хакасия',
        value: 87000,
        tps: [
            {
                name: 'Абаканская ТЭЦ',
                slug: 'abakanskaya-tets',
            },
        ],
    },
    {
        name: 'Канск',
        slug: 'kansk',
        region: 'Красноярский край',
        value: 28000,
        tps: [
            {
                name: 'Канская ТЭЦ',
                slug: 'kanskaya-tets',
            },
        ],
    },
    {
        name: 'Зеленогорск',
        slug: 'zelenogorsk',
        region: 'Красноярский край',
        value: 198000,
        tps: [
            {
                name: 'Красноярская ГРЭС-2',
                slug: 'krasnoyarskaya-gres-2',
            },
        ],
    },
    {
        name: 'Красноярск',
        slug: 'krasnoyarsk',
        region: 'Красноярский край',
        value: 260000,
        tps: [
            {
                name: 'Красноярская ТЭЦ-1',
                slug: 'krasnoyarskaya-tets-1',
            },
            {
                name: 'Красноярская ТЭЦ-2',
                slug: 'krasnoyarskaya-tets-2',
            },
            {
                name: 'Красноярская ТЭЦ-3',
                slug: 'krasnoyarskaya-tets-3',
            },
        ],
    },
    {
        name: 'Минусинск',
        slug: 'minusinsk',
        region: 'Красноярский край',
        value: 17000,
        tps: [
            {
                name: 'Минусинская ТЭЦ',
                slug: 'minusinskaya-tets',
            },
        ],
    },
    {
        name: 'Назарово',
        slug: 'nazarovo',
        region: 'Красноярский край',
        value: 150000,
        tps: [
            {
                name: 'Назаровская ГРЭС',
                slug: 'nazarovskaya-gres',
            },
        ],
    },
    {
        name: 'Лучегорск',
        slug: 'luchegorsk',
        region: 'Приморский край',
        value: 1211000,
        tps: [
            {
                name: 'Приморская ГРЭС',
                slug: 'primorskaya-gres',
            },
        ],
    },
];
